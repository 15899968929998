import React from "react";

export default function Footer() {
  return (
    <footer>
      <div className="content-wrap">
        <h2>Contact Information</h2>

        <ul className="links">
          <li>
            <a href="mailto:mail@gatorbait.io">Email</a>
          </li>
          <li>
            <a href="https://twitter.com/bradblockchain">Twitter</a>
          </li>

          <li>
            <a href="https://github.com/gatorbait-eth">Github</a>
          </li>

          <li>
            <a href="https://www.linkedin.com/in/bradleymentzer/">LinkedIn</a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
