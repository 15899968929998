import React from "react";
import Headshot from "./../images/headshot.jpeg";
import School from "./../images/UF-photo.jpeg";

const Home = () => (
  <>
    <body>
      <main>
        {/*  Bio  */}
        <header>
          <div className="content-wrap">
            <h1>Bradley A. Mentzer</h1>
            <h2>Full-Stack Developer</h2>

            <p>Finding solutions to global healthcare inequity.</p>
            <p>"Scared money don't make money"</p>
          </div>
        </header>

        {/* Menu */}

        <article className="profile-pic">
          <div className="content-wrap">
            <img src={Headshot} alt="headshot" />;
          </div>
        </article>
        {/*Stack*/}
        <section className="stack">
          <div className="content-wrap">
            <h2>Featured</h2>

            <p>
              My passion lies within education and mentorship. Whether it is
              through medical assistance, education programs, or writing
              programs that provide a digital infrastructure, my mission is to
              do my part, however modest, for a globalizing world.
            </p>

            <section>
              <p>
                Formally, my topic of study has been medicine. Since being
                introduced to web3 in 2020, my interest for blockchain
                technology has significantly grown as I continue to learn more
                about development in an effort to contribute to this growing
                world.
              </p>
              <h3>Stack</h3>
              <ul>
                <ul>
                  <h4>Languages</h4>
                  <li>
                    HTML, CSS, Javascript, Typescript, Bash, Circom, Python,
                    Solidity
                  </li>
                </ul>
                <ul>
                  <h4>Tools</h4>
                  <li>
                    React, Redux, NextJS, NodeJS, Express, Styled-Components,
                    Tailwind, Truffle, Hardhat
                  </li>
                </ul>
                <ul>
                  <h4>Network</h4>
                  <li>MongoDB, AWS, Cloudflare Workers</li>
                </ul>
                <ul>
                  <h4>Organizations</h4>
                  <li>BanklessDAO, eGlote, CodeSportLabs, BuidlGuidl</li>
                </ul>
              </ul>
            </section>
          </div>
        </section>

        {/*Work Experience*/}
        <section className="work-experience">
          <div className="content-wrap">
            <h2>Work Experience</h2>
            <section>
              <h3>Emergency Department Technician</h3>
              <p>Cleveland Clinic Florida- Indian River</p>
              <p>August 2021 - May 2022</p>
              <p>Responsibilities:</p>
              <ul>
                <li>
                  Performed wound care and splinting to aid the healing process
                </li>
                <li>
                  Monitored EKG rhythm strips to prevent the progression of
                  fatal arrhythmias
                </li>
                <li>
                  Identified a variety of emergency situations: stroke,
                  respiratory distress, cardiac arrest
                </li>
                <li>
                  Executed lifesaving aid while assisting physicians with a
                  variety of tasks to assure quality of care
                </li>
              </ul>
            </section>

            <section>
              <h3>Lead Scribe</h3>
              <p>Quality Healthcare- Sebastian, FL</p>
              <p>September 2020- July 2021</p>
              <p>Responsibilities:</p>
              <ul>
                <li>
                  Trained new scribes and providers in navigating ECW
                  effectively
                </li>

                <li>Scribed for Mark Hasenauer PA-C and Dr Harish Sadhwani</li>
                <li>Sent all patient referrals and medications</li>

                <li>
                  Updated all billing and diagnostic codes to maintain charting
                  integrity
                </li>
              </ul>
            </section>
          </div>
        </section>

        {/*Education*/}
        <section className="education">
          <div className="content-wrap">
            <h2>Education</h2>
            <section>
              <h3>University of Florida - Gainesville, FL</h3>
              <p>Bachelors of Science in Biochemistry, 2016-2020</p>
              <p>Arts in Medicine Certificate, Minor in Music</p>
              <p>BLS Certification, American Heart Association</p>
            </section>
            <img src={School} alt="SchoolPhoto" />;
          </div>
        </section>
      </main>
    </body>
  </>
);

export default Home;
