import React from "react";

export default function Nav() {
  return (
    <ul>
      <li>
        <a href="/" title="Home">
          Home <br />
          {/* <span className="subheading">Who am I?</span> */}
        </a>
      </li>
      <li>
        <a href="/about" title="About">
          About <br />
          {/* <span className="subheading">My passsions</span> */}
        </a>
      </li>
      <li>
        <a href="/nfts" title="My NFT Collection">
          NFTs <br />
          {/* <span className="subheading">What I'm collecting</span> */}
        </a>
      </li>
    </ul>
  );
}
