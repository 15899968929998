import React from "react";
import H2232 from "./../images/habibi2232.gif";
import H1109 from "./../images/habibi1109.png";
import H3400 from "./../images/habibi3400.png";
import G6467 from "./../images/gape6467.jpeg";
import S128 from "./../images/sloth128.png";
import K1587 from "./../images/kid1587.png";
import K4788 from "./../images/kid4788.png";
import K696 from "./../images/kid696.png";

const Nft = () => (
  <>
    <body>
      <header>
        <div className="content-wrap">
          <h2>NFTs</h2>
        </div>
      </header>

      <ul>
        <img src={H2232} alt="habibi2232" />
        <img src={H1109} alt="habibi1109" />
        <img src={H3400} alt="habibi3400" />
        <img src={G6467} alt="gape6467" />
        <img src={S128} alt="sloth128" />
        <img src={K1587} alt="kid1587" />
        <img src={K4788} alt="kid4788" />
        <img src={K696} alt="kid696" />
      </ul>
      <div className="content-wrap">
        <p>
          My full collection can be found at{" "}
          <a className="btn" href="https://opensea.io/gatorgrowl">
            opensea.io
          </a>
          .
        </p>
      </div>
    </body>
  </>
);

export default Nft;
