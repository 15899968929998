import React from "react";
import Mwanza from "./../images/mwanza.png";

const About = () => (
  <body>
    <header>
      <div className="content-wrap">
        <h2>My Projects</h2>
      </div>
    </header>
    <section className="mwanza">
      <div className="content-wrap">
        <h2>Health Education Conference</h2>
        <section>
          <h3>Mwanza, Tanzania</h3>
          <p>
            During the summer of 2021, I had the opportunity to accompany Father
            Jonathon Robbins to Mwanza, Tanzania, to be a guest speaker hosted
            by Dr. Bela Kalumbete. This three day conference was implemented to
            bring awareness to mental and physical health and covered a wide
            range of topics such as preventing burnout, proper nutrition, and
            the importance of vaccines.
          </p>
          <p>
            These men are all highly intelligent and honored in their respective
            field. However, there is little to no access to medical education on
            chronic diseases. This inspires me towards the frontier of
            technology. The future of healthcare will transition towards better
            tools, with intuitive UI that empower patients and providers. Using
            the advances made in medicine within the last 10 years, patients
            will be able to have more autonomy in managing their health.
          </p>
        </section>
        <img src={Mwanza} alt="Mwanza" />
      </div>
    </section>
  </body>
);

export default About;
